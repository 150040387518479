<template>
<div>
  <div class="main-title">套餐管理</div>
  <div class="wrap">
    <div class="left">
      <div class="left-top">
        <div class="item">
          <img src="@/assets/imgs/ticket_orange.png" alt="">
          <div class="desc">
            <div v-if="userInfo.schemes_id === 0">未购买</div>
            <div v-else class="pointer" @click="swiper.slideTo(findIndex, 600, false);activePackage = findIndex">{{userInfo.schemes_name}}</div>
            <span>当前套餐</span>
          </div>
          <el-button v-if="userInfo.schemes_id !== 0" class="btn" @click="showRenew">续费 ></el-button>
        </div>
        <div class="item">
          <img src="@/assets/imgs/ticket_green.png" alt="">
          <div class="desc">
            <div>{{userInfo.user_num}}</div>
            <span>子账户数</span>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/imgs/ticket_red.png" alt="">
          <div class="desc">
            <div>{{userInfo.browser_num}}</div>
            <span>店铺数</span>
          </div>
        </div>
      </div>
      <div class="left-bottom">
        <div class="top">
          <swiper :options="swiperOption" ref="mySwiper">
            <swiper-slide class="item" v-for="(item,index) in packageList" :key="item.name" :class="activePackage === index ? 'active-package' : ''">
              <div class="item-top" @click.prevent="activeClick(index)" :class="findIndex !== -1 && index < findIndex ? 'un-active' : ''">
                <div class="title">{{item.name}}</div>
                <div class="desc">¥ <div>{{item.price}}</div> <span>/ 月</span> </div>
              </div>
              <div class="item-bottom" @click.prevent="activeClick(index)" :class="findIndex !== -1 && index < findIndex ? 'un-active' : ''">
                <div v-html="item.content"></div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
        <div class="bottom">
          <div class="title">期限</div>
          <div class="price-wrap">
            <div class="item" v-for="(item,index) in monthList" :key="item.count" :class="activeMonth === index ? 'active-month': ''" @click="activeMonth = index">
              <span>{{item.count}}个月</span>
              <div v-if="item.score !== 0 ">{{item.score}}折</div>
              <img src="@/assets/imgs/month_check.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right" v-if="packageList.length > 0">
      <div class="title">订单详情</div>
      <div class="price">
        <div class="coin">
          <img src="@/assets/imgs/coin.png" alt="">
          <div>{{packageList[activePackage].name}}</div>
        </div>
        <div class="price-desc">¥{{packageList[activePackage].price}} <span>/月</span> </div>
      </div>
      <div class="desc">
        <div>详情: </div>
        <div class="desc-check" v-html="packageList[activePackage].content"></div>
      </div>
      <div class="buy-time">
        <div class="time">
          <div>购买时长：</div>
          <!-- <div>可使用至 2023-12-05</div> -->
        </div>
        <div>{{monthList[activeMonth].count}}个月</div>
      </div>
      <div class="main-price">
        <div class="price-left">总计费用：</div>
        <div class="price-right">
          <div>¥{{finalPrice}}</div>
          <!-- <span>¥199</span> -->
        </div>
      </div>
      <el-button class="order-btn" @click="packageOrder" :loading="btnLoading">提交订单</el-button>
      <div class="read">
        <el-checkbox v-model="checked"></el-checkbox>
        <div>我已阅读并同意 <span @click="toService">《技术服务协议》</span> 、<span @click="toRefund">《退款规则》</span> </div>
      </div>
    </div>
  </div>
  <el-dialog :visible.sync="showDialog" v-if="showDialog" width="40%">
    <PayInfo :order="order" :type="'package'" @closeAll="closeAll" />
  </el-dialog>
  <PackageRenew :renewDialog.sync="renewDialog" v-if="renewDialog" @closeAll="closeAll" />
</div>
</template>

<script>
import PayInfo from '@/components/pay-info.vue'
import PackageRenew from '@/components/package-renew.vue'
import {packageLists,packageOrder} from '@/api/index'
import {swiper, swiperSlide} from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import {mapGetters} from 'vuex'
export default {
  components:{
    PayInfo,
    PackageRenew,
    swiper,
    swiperSlide
  },
  data(){
    return{
      renewDialog: false,
      activePackage: 0,
      activeMonth: 0,
      packageList: [],
      monthList: [
        {
          count: 1,
          score: 0
        },
        {
          count: 3,
          score: 93
        },
        {
          count: 6,
          score: 85
        },
        {
          count: 12,
          score: 75
        },
      ],
      showDialog: false,
      order: {},
      checked: false,
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 20,
        notNextTick: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      },
      btnLoading: false
    }
  },
  computed:{
    ...mapGetters(['userInfo']),
    finalPrice(){
      let score = 0
      if(this.monthList[this.activeMonth].score === 0){
        score = 1
      }else{
        score = this.monthList[this.activeMonth].score / 100
      }
      if(this.userInfo.schemes_id === 0 || this.userInfo.expire_days <= 3){
        return Math.floor(Number(this.packageList[this.activePackage].price) * this.monthList[this.activeMonth].count * score * 100) / 100 
      }else{
        let oldPackagePrice = this.packageList.find(item => item.name === this.userInfo.schemes_name).price
        let result = (parseInt(this.packageList[this.activePackage].price / 30) * this.userInfo.expire_days - parseInt(oldPackagePrice / 30) * this.userInfo.expire_days) + (this.packageList[this.activePackage].price * this.monthList[this.activeMonth].count * score)
        return result.toFixed(2)
      }
    },
    swiper(){
      return this.$refs.mySwiper.swiper
    },
    findIndex(){
      return this.packageList.findIndex(item => item.id === this.userInfo.schemes_id)
    }
  },
  created(){
    this.packageLists()
  },
  methods:{
    activeClick(index){
      if(this.findIndex !== -1){
        if(index >= this.findIndex){
          this.activePackage = index
        }
      }else{
        this.activePackage = index
      }
    },
    packageLists(){
      packageLists().then(res => {
        let {result} = res
        let data = this.$rsaDecrypt(result)
        console.log(data)
        this.packageList = data.lists
        if(this.findIndex !== -1){
          this.activePackage = this.findIndex
          this.$nextTick(() => {
            this.swiper.slideTo(this.findIndex, 600, false);
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    packageOrder(){
      if(this.checked){
        this.btnLoading = true
        let req = {
          time_limit: this.monthList[this.activeMonth].count,
          schemes_id: this.packageList[this.activePackage].id,
          payment_amount: this.packageList[this.activePackage].price,
          buy_type: 1
          // payment_amount: '0.01'
        }
        let requestData = this.$rsaEncryptForm(req, "SIJa%$%3hu12hu(*1278")
        packageOrder(requestData).then(res => {
          this.btnLoading = false
          this.order = {
            name: this.packageList[this.activePackage].name,
            content: this.packageList[this.activePackage].content,
            time_limit: this.monthList[this.activeMonth].count,
            schemes_id: this.packageList[this.activePackage].id,
            payment_amount: this.packageList[this.activePackage].price,
            total_amount: res.result.total_amount,
            alipay_qr_code: res.result.alipay_qr_code,
            order_no: res.result.order_no,
            expire_time: res.result.expire_time
          }
          console.log(111,res)
          this.showDialog = true
        }).catch(err => {
          this.btnLoading = false
        })
      }else{
        this.$message.error('请勾选并同意协议和规则！')
      }
    },
    showRenew(){
      this.renewDialog = true
    },
    closeAll(){
      this.showDialog = false
      this.renewDialog = false
      this.packageLists()
    },
    toService(){
      window.open('https://www.etsyzs.com/service')
    },
    toRefund(){
      window.open('https://www.etsyzs.com/refund/')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog{
    margin-top: unset !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}
.main-title{
 font-size: 24px;
 padding-bottom: 50px; 
 font-weight: bold;
}
.un-active{
  // .item-top{
    background-color: #eee;
  // }
}
.wrap{
  display: flex;
  // align-items: center;
  justify-content: space-between;
  .left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .left-top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item{
        // width: 368px;
        height: 165px;
        padding: 0 40px;
        border: 1px solid #DCDCDC;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        margin-right: 30px;
        &:last-child{
          margin: unset;
        }
        img{
          width: 56px;
          margin-right: 40px;
        }
        .desc{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          div{
            padding-bottom: 20px;
            font-size: 30px;
          }
          span{
            color: #4C4C4C;
            font-size: 18px;
          }
        }
        .btn{
          width: 89px;
          height: 49px;
          background: rgb(254,236,217);
          color: #FF6E00;
          border-radius: 10px;
          margin-left: 40px;
          border: unset;
        }
      }
    }
    .left-bottom{
      border: 1px solid #DCDCDC;
      border-radius: 3px;
      padding: 40px;
      .top{
        width: 45vw;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 36px;
        position: relative;
        .item{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .item-top{
            padding: 35px 86px;
            background-size: 100% 100%;
            border: 1px solid #DDDDDD;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .title{
              font-size: 20px;
              padding-bottom: 14px;
              font-weight: bold;
            }
            .desc{
              display: flex;
              font-size: 20px;
              div{
                font-size: 45px;
                font-weight: bold;
                color: #F56735;
              }
              span{
                padding-left: 6px;
                padding-top: 24px;
                font-size: 13px;
                color: #333333;
                white-space: nowrap;
              }
            }
          }
          .item-bottom{
            padding: 50px 76px;
            border: 1px solid #DDDDDD;
            border-radius: 3px;
            border-top: unset;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            div{
              font-size: 16px;
              &:first-child{
                padding-bottom: 26px;
              }
            }
          }
        }
        .active-package{
            .item-top{
              background-image: url('~@/assets/imgs/zy_hover.png');
              color: #fff;
              .desc{
                div,span{
                  color: #fff;
                }
              }
            }
          }
      }
      .bottom{
        display: flex;
        align-items: center;
        justify-content: center;
        .title{
          padding-right: 80px;
        }
        .price-wrap{
          display: flex;
          align-items: center;
          justify-content: center;
          .item{
            padding: 32px 40px;
            border: 2px solid #DCDCDC;
            border-radius: 2px;
            position: relative;
            margin-right: 40px;
            cursor: pointer;
            div{
              width: 70px;
              height: 16px;
              background-image: url('~@/assets/imgs/month.png');
              background-size: 100% 100%;
              position: absolute;
              left: 50%;
              top: -2px;
              transform: translateX(-50%);
              text-align: center;
              line-height: 15px;
              color: #fff;
              font-size: 12px;
            }
            img{
              position: absolute;
              right: -1px;
              bottom: 0;
              width: 16px;
              display: none;
            }
          }
          .active-month{
            border: 2px solid #ED514C;
            img{
              display: block;
            }
          }
        }
      }
    }
  }
  .right{
    padding: 45px 40px 37px 40px;
    border: 1px solid #DCDCDC;
    border-radius: 3px;
    .title{
      font-size: 22px;
      font-weight: bold;
      padding-bottom: 45px;
    }
    .price{
      background: #F2F3F3;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      .coin{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 35px;
          padding-right: 8px;
        }
        div{
          font-size: 18px;
          color: #333;
        }
      }
      .price-desc{
        font-size: 18px;
        span{
          color: #999999;
        }
      }
    }
    .desc{
      display: flex;
      justify-content: space-between;
      padding: 90px 0;
      div{
        &:first-child{
          color: #333;
        }
        
      }
      .desc-check{
          color: #333;
          display: flex;
          flex-direction: column;
          white-space: nowrap;
          .check{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 20px;
            .el-icon-check{
              font-size: 20px;
              color: #48C741;
              padding-right: 20px;
            }
            
          }
        }
    }
    .buy-time{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 64px;
      border-bottom: 1px solid #DCDCDC;
      .time{
        div{
          &:first-child{
            color: #333333;
          }
          &:last-child{
            color: #999999;
          }
        }
      }
    }
    .main-price{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 50px 0;
      .price-right{
        div{
          font-size: 24px;
          font-weight: bold;
          color: #323C47;
        }
        span{
          color: #999999;
          text-decoration:line-through;
        }
      }
    }
    .order-btn{
      width: 100%;
      background-color: #FF6E00;
      color: #fff;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      border: unset;
      font-size: 18px;
    }
    .read{
      display: flex;
      align-items: center;
      justify-content: center;
      div{
        padding-left: 20px;
        span{
          color: #548AF6;
          cursor: pointer;
        }
      }
    }
  }
}

.swiper-button-prev,.swiper-button-next{
  background-image: url('~@/assets/imgs/arrow.png');
}
.swiper-button-prev{
  transform: rotate(180deg);
}

</style>